<template>
  <div class="container mt-sm-5">
    <div class="row align-items-center jutify-content-start mt-4 paragraph-03 text-neutral-05">
      <div class="col-auto pe-2">
        <router-link :to="{ name: 'HomePage' }" class="text-neutral-05"> Acasă </router-link>
      </div>
      <div class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div class="col-auto pe-2">
        <a
          href="/produse"
          :class="[
            filter.Category && filter.Category.Id
              ? 'text-neutral-05'
              : 'text-secondary-05 text-button-02',
          ]"
        >
          Produse
        </a>
      </div>
      <div v-if="filter.Category && filter.Category.Id" class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg" />
      </div>
      <div
        v-if="filter.Category && filter.Category.Id"
        class="text-button-02 col-auto text-secondary-05"
      >
        {{ filter.Category.Name }}
      </div>
    </div>
    <!--
    <carousel
      class="products-categories-carousel"
      ref="categoriesCarouselRef"
      :breakpoints="carouselBreakPoints"
    >
      <slide v-for="(category, index) in categories" :key="index">
        <div
          class="dropdown h-100"
          style="width: 92%"
          @mouseover="category.Subcategories.length > 0 ? (category.ShowSubcategories = true) : ''"
          @mouseleave="category.ShowSubcategories = false"
        >
          <button
            class="btn-dropdown-outlined w-100 d-none d-md-flex align-items-center justify-content-between"
            :class="{
              'dropdown-toggle': category.Subcategories.length > 0,
              'border-primary-05': filter.Category.Name === category.Name,
            }"
            type="button"
            @click="FilterByCategory(category)"
          >
            <span>
              <img v-if="category.Img" :src="GetAttachmentName(category.Img)" alt="..." />
              <span
                class="text-button-02"
                :class="[
                  filter.Category.Name === category.Name ? 'text-primary-05' : 'text-neutral-04',
                ]"
              >
                {{ category.Name }}
              </span>
            </span>
          </button>
          <button
            class="btn-dropdown-outlined w-100 d-md-none text-center pt-1 pb-1"
            :class="{ 'border-primary-05': filter.Category.Name === category.Name }"
            type="button"
            @click="FilterByCategory(category)"
          >
            <img
              v-if="category.Img"
              :src="GetAttachmentName(category.Img)"
              class="me-0"
              alt="..."
            />
            <div
              class="text-button-02 mt-2"
              :class="[
                filter.Category.Name === category.Name ? 'text-primary-05' : 'text-neutral-04',
              ]"
            >
              {{ category.Name }}
            </div>
          </button>
          <ul class="dropdown-menu w-100" :class="{ show: category.ShowSubcategories }">
            <li
              v-for="(subcateg, indexSubcateg) in category.Subcategories"
              :key="indexSubcateg"
              @click="
                FilterBySubcatgory(subcateg);
                filter.Category = category;
              "
            >
              <span
                class="dropdown-item d-flex align-items-center"
                :class="{ active: subcateg == filter.Subcategory }"
              >
                {{ subcateg.Name }}
              </span>
              <hr
                class="dropdown-divider me-3 ms-3 border-color-blacktint-1"
                v-if="indexSubcateg < category.Subcategories.length - 1"
              />
            </li>
          </ul>
        </div>
      </slide>

      <template #addons>
        <navigation>
          <template #prev>
            <img src="../../assets/images/icons/chevron-left-32x32.svg" alt="..." />
          </template>
          <template #next>
            <img src="../../assets/images/icons/chevron-right-32x32.svg" alt="..." />
          </template>
        </navigation>
      </template>
    </carousel> -->

    <!-- <div class="heading-05">
      <span :class="[filter.Subcategory.Name ? 'text-neutral-05' : 'text-neutral-07']">
        {{ filter.Category.Name === 'Toate' ? 'Toate categoriile' : filter.Category.Name }}
      </span>
      <span v-if="filter.Subcategory.Name" class="me-2 ms-2">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.45334 4.55326L5.45334 8.70659L5.45334 12.4466C5.45334 13.0866 6.22667 13.4066 6.68001 12.9533L10.1333 9.49992C10.6867 8.94659 10.6867 8.04659 10.1333 7.49326L8.82 6.17992L6.68001 4.03992C6.22667 3.59326 5.45334 3.91326 5.45334 4.55326Z"
            fill="#95A6AC"
          />
        </svg>
      </span>
      <span class="text-neutral-07">
        {{ filter.Subcategory.Name }}
      </span>
    </div>
    <div class="row align-items-center mt-4 ms-0 me-0">
      <div class="col-sm-7 mb-3 mb-sm-0 col-lg-5">
        <div class="input-group filter-border left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-linear-24x24.svg" alt="..." />
          </span>
          <input
            id="searchText"
            type="text"
            class="form-control"
            name="searchText"
            placeholder="Cautare..."
            v-model="filter.SearchQuery"
            @keyup.enter="GetProducts"
          />
        </div>
      </div>
      <div class="col d-none d-lg-block"></div>
      <div class="col col-lg-3">
        <div class="dropdown filter-border" :class="{ 'value-selected': filter.OrderBy }">
          <button
            class="btn-dropdown-outlined w-100 dropdown-toggle"
            type="button"
            id="orderByDropdownMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span v-if="filter.OrderBy"> Sortare: {{ filter.OrderBy.Name }} </span>
            <span v-else> Toate </span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="orderByDropdownMenu">
            <li
              @click="
                filter.OrderBy = item;
                GetProducts();
              "
              v-for="(item, index) in orderList"
              :key="index"
            >
              <span
                class="dropdown-item d-flex align-items-center"
                :class="{ active: filter.OrderBy.Param === item.Param }"
              >
                {{ item.Name }}
              </span>
              <hr
                class="dropdown-divider me-3 ms-3 border-color-blacktint-1"
                v-if="index < orderList.length - 1"
              />
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <!--products-->
    <div class="row ms-0 me-0 mt-4" v-if="placeholderLoader">
      <div class="col-6 col-lg-4 col-xl-3 mb-3 pb-1" v-for="(item, index) in 8" :key="index">
        <product-loader-component />
      </div>
    </div>
    <div class="row ms-0 me-0 mt-4" v-if="!placeholderLoader && products && products.length > 0">
      <div
        class="col-6 col-lg-4 col-xl-3 mb-3 pb-1"
        v-for="(product, index) in products"
        :key="index"
        @mouseover="product.ShowCartButtons = true"
        @mouseleave="product.ShowCartButtons = false"
      >
        <DisplayProduct :product="product" @change="ChangeFavoriteStatus" />
        <add-to-cart-component
          :product="product"
          :user="currentUser"
          @prod-added-success-in-cart="ShowSuccessPopUp"
          @close-pop-up="CloseSuccessPopUp()"
        />
      </div>
    </div>

    <div
      v-if="products && products.length == 0 && (filter.SearchQuery || filter.FetchData)"
      class="mt-5 mb-5"
    >
      <not-found-component text="Nu există rezultate." />
    </div>

    <div ref="endProductsRef"></div>
  </div>

  <template v-if="showSuccessPopup && popUpComponent">
    <component
      :is="popUpComponent"
      @close-pop-up="CloseSuccessPopUp()"
      :showSuccessPopup="showSuccessPopup"
    />
  </template>
</template>

<script>
/* eslint-disable */
import DisplayProduct from '../../components/Product/DisplayProductComponent.vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import AddToCartComponent from '../../components/General/AddToCartComponent.vue';
import { markRaw } from 'vue';
import ProductAddedToCartPopUpComponent from '../../components/Product/ProductAddedToCartPopUpComponent.vue';
import ProductLoaderComponent from '../../components/Product/ProductLoaderComponent.vue';
import NotFoundComponent from '../../components/General/NotFoundComponent.vue';

export default {
  name: 'ProductsView',
  data() {
    return {
      categories: [],
      orderList: [
        { Name: 'Alfabetic A-Z', Param: 'alphabetic' },
        { Name: 'Alfabetic Z-A', Param: 'alphabetic_desc' },
        { Name: 'Pret crescator', Param: 'price' },
        { Name: 'Pret descrescator', Param: 'price_desc' },
        { Name: 'In stoc', Param: '' },
        { Name: 'Recomandate', Param: 'recommended' },
      ],
      products: [],
      filter: {
        PageNumber: 1,
        PageSize: 20,
        SearchQuery: '',
        Category: {
          Name: 'Toate',
        },
        Subcategory: '',
        OrderBy: { Name: 'In stoc', Param: '' },
        FetchData: false,
      },
      pagination: {
        TotalItemCount: 0,
        TotalPageCount: 0,
        PageSize: 0,
      },
      carouselBreakPoints: {
        0: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        350: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        768: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
        992: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
      placeholderLoader: false,
      showSuccessPopup: false,
      popUpComponent: null,
    };
  },
  components: {
    DisplayProduct,
    Carousel,
    Slide,
    // PaginationProducts,
    Navigation,
    AddToCartComponent,
    NotFoundComponent,
    // PaginationComponent,
    ProductLoaderComponent,
  },
  methods: {
    ShowSuccessPopUp() {
      this.popUpComponent = markRaw(ProductAddedToCartPopUpComponent);
      this.showSuccessPopup = true;
    },
    CloseSuccessPopUp() {
      this.showSuccessPopup = false;
      this.popUpComponent = null;
    },
    SetClickedProduct() {
      $(document).ready(function () {
        const anchors = document.querySelectorAll('a[title*="Vezi produsul"]');
        anchors.forEach((anchor) => {
          anchor.addEventListener('click', function (event) {
            const closestAnchor = event.target.closest('a[title*="Vezi produsul"]');
            sessionStorage.setItem('clickedProductSlug', closestAnchor.id);
          });
        });
      });
    },
    GetAttachmentName(imgName) {
      return require(`@/attachments/categories/${imgName}.svg`);
    },
    GetProducts() {
      this.placeholderLoader = true;
      this.filter.FetchData = false;
      const searchParams = {
        ...(this.filter.Category.Id ? { CategoryId: this.filter.Category.Id } : ''),
        ...(this.filter.Subcategory ? { SubcategoryId: this.filter.Subcategory.Id } : ''),
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
        ...(this.filter.OrderBy ? { OrderBy: this.filter.OrderBy.Param } : ''),
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
      };
      this.$axios
        .get(`/Product/${this.$utils.ApiNameByCurrentUser('getAll')}?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          response.data.Items = response.data.Items.map((item) => ({ ...item, Amount: 1 }));
          this.products = [...this.products, ...response.data.Items];
          this.pagination = response.data.PageDetails;
          this.placeholderLoader = false;
          this.filter.FetchData = true;
          this.SetClickedProduct();
        })
        .catch(() => {
          this.placeholderLoader = false;
          this.filter.FetchData = true;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    async GetCategories() {
      // this.$store.state.loader = true;
      await this.$axios
        .get('/Category/getList')
        .then((response) => {
          this.categories = response.data;
          this.categories = [
            {
              Id: 0,
              Name: 'Toate',
              Img: '',
              Subcategories: [],
            },
            ...this.categories,
          ];
          // this.$store.state.loader = false;
        })
        .catch(() => {
          // this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    async ChangeFavoriteStatus(product) {
      if (this.currentUser) {
        this.$store.state.loader = true;
        if (product.IsFavorit === false) {
          await this.$axios.post(`/User/addToFavorite/${product.Id}`);
          product.IsFavorit = true;
        } else {
          await this.$axios.delete(`/User/removeFromFavorite/${product.Id}`);
          product.IsFavorit = false;
        }
        this.$store.state.loader = false;
      }
    },
    FilterByCategory(category) {
      if (category.Id) {
        this.filter.Category = category;
        this.filter.Subcategory = '';
      } else {
        this.filter.Category = '';
        this.filter.Subcategory = '';
        this.filter.Category = {
          Name: category.Name,
        };
      }
      this.GetProducts();
    },
    FilterBySubcatgory(subcategory) {
      this.filter.Subcategory = subcategory;
      this.GetProducts();
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr;
      this.GetProducts();
    },
    ScrollToPreviousProduct(slug) {
      const productExists = this.products.some(
        (item) => this.$utils.StringToSlug(item.Title) === slug
      );
      if (productExists) {
        $(document).ready(function () {
          const prod = document.getElementById(slug);
          // console.log(prod);
          if (prod) {
            window.scrollTo({
              top: prod.offsetTop - 200,
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        });
      }
    },
  },
  mounted() {
    const options = {
      threshold: 0.5,
    };
    var self = this;
    const observer = new IntersectionObserver(function (entries, observer) {
      if (entries[0].isIntersecting) {
        if (self.filter.FetchData) {
          const increment = self.filter.PageNumber + 1;
          if (increment <= self.pagination.TotalPageCount) self.SelectPage(increment);
        }
      }
    }, options);
    observer.observe(this.$refs.endProductsRef);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    if (this.$route.params && this.$route.params.categoryId) {
      await this.GetCategories();
      const category = this.categories.find(
        (categ) => categ.Id === parseInt(this.$route.params.categoryId, 10)
      );
      if (category) this.FilterByCategory(category);
    } else this.GetProducts();

    //scroll to previous product
    const slug = sessionStorage.getItem('clickedProductSlug');
    if (slug) {
      sessionStorage.removeItem('clickedProductSlug');
      this.$watch('products', (n, o) => {
        this.ScrollToPreviousProduct(slug);
      });
    }
  },
};
</script>

<style scoped>
.heading-05 {
  margin: 20px 0px;
}
@media only screen and (min-width: 576px) {
  .heading-05 {
    margin: 40px 0px;
  }
}

.products-categories-carousel {
  margin-top: 38px;
  z-index: 6;
}

@media only screen and (max-width: 650px) {
  .products-categories-carousel {
    margin-right: 10%;
    margin-left: 10%;
  }
}
.btn-dropdown-outlined {
  padding: 14px 16px;
  height: 100%;
}
.btn-dropdown-outlined img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 12px;
  margin-right: 13.5px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1260px;
  }
}

.container{
  min-height: 81vh;
}
</style>
